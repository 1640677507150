import React, { useState } from "react"
import { graphql, navigate, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"

import "../css/theme/_ad-campaigns.scss"

const AdCampaignB = ({ pageContext, location, data }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [date, setDate] = useState("")

  const { language } = pageContext
  const { hero, body, metaTitle, metaDescription } =
    data.allAdCampaignsJson.nodes[0]

  const address = data.address.nodes[0].locationData

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("firstName", firstName)
    formData.append("lastName", lastName)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("date", date)
    formData.append("from", location.href)

    setIsLoading(true)
    fetch("https://getform.io/f/ajjewjoa", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => {
        navigate("/thank-you/")
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  return (
    <Layout className="campaign" language={language} noNav noFooter>
      <SEO
        robots="noindex"
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <nav className="campaign__nav">
        <Link
          className="campaign__logo clear-decoration"
          to="/"
          title="Return to homepage">
          <div>
            <Image
              useAR={false}
              effect="trim"
              publicId="WOD/DEV/logo"
              className="desktop"
            />
            <Image
              useAR={false}
              effect="trim"
              publicId="WOD/DEV/logo-mark"
              className="mobile"
            />
          </div>
          <p>The Wisdom Teeth Specialists</p>
        </Link>

        <p className="campaign__address" style={{ color: "#00174c" }}>
          <span>
            {`${address.streetNumber} ${address.streetName}, `}
            <br className="mobile" />
            {` ${address.cityName}, ${address.stateAbbr} ${address.cityZip}`}
          </span>
        </p>
      </nav>

      <div className="campaign b relative">
        <Section zeroBottomDesktop zeroTop>
          <Columns>
            <div className="column is-2"></div>
            <div className="column">
              <div className="campaign__hero">
                <div className="campaign__hero--content">
                  <Text as="h1" text={hero.heading} />

                  {/* <div className="callout mt-2--mobile mb-2--mobile is-hidden-desktop">
                    <h3 className="color-blue">
                      OCTOBER
                      <br />
                      IS WISDOM
                      <br />
                      TEETH
                      <br />
                      MONTH
                    </h3>
                    <Image publicId="WOD/DEV/meet-dr-johnson" />
                  </div> */}

                  <Text
                    className="campaign__hero--subheading mb-0"
                    text={hero.subheading}
                  />

                  <Button
                    href="#wisdom-teeth-month-form"
                    buttonText="Book a free Consultation"
                    destination="anchor"
                    contained
                    className="mb-3 mb-1--mobile"
                  />

                  {hero.button && hero.button.href && (
                    <Button
                      buttonText={hero.button.buttonText}
                      href={hero.button.href}
                      appearance={hero.button.appearance}
                      destination={hero.button.destination}
                    />
                  )}
                </div>
                <div className="campaign__hero--image">
                  <Image publicId={hero.image} />

                  {/* <div className="callout is-hidden-touch">
                    <h3 className="color-blue">
                      OCTOBER
                      <br />
                      IS WISDOM
                      <br />
                      TEETH
                      <br />
                      MONTH
                    </h3>
                    <Image publicId="WOD/DEV/meet-dr-johnson" />
                  </div> */}
                </div>
              </div>
            </div>
          </Columns>
        </Section>

        <Section colorBack className="rounded-top">
          <Columns sideColumnsSize={2}>
            <div className="column">
              <div className="campaign__grid even">
                <div>
                  <Text text={body} />
                </div>
                <div>
                  <h3
                    className="color-blue-dark mb-1"
                    style={{ fontSize: "32px" }}>
                    Appointment Form
                  </h3>
                  <form
                    id="wisdom-teeth-month-form"
                    className="campaign__form"
                    onSubmit={handleSubmit}>
                    <input
                      name="firstName"
                      value={firstName}
                      type="text"
                      placeholder="First Name"
                      onChange={e => setFirstName(e.target.value)}
                      required
                    />
                    <input
                      name="lastName"
                      value={lastName}
                      type="text"
                      placeholder="Last Name"
                      onChange={e => setLastName(e.target.value)}
                      required
                    />
                    <input
                      name="email"
                      value={email}
                      type="email"
                      placeholder="you@email.com"
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                    <input
                      name="phone"
                      value={phone}
                      type="tel"
                      placeholder="(xxx) xxx-xxx"
                      onChange={e => setPhone(e.target.value)}
                      required
                    />
                    <div>
                      <label
                        style={{ marginBottom: "5px", paddingLeft: "20px" }}
                        className="color-blue-dark bold--600 block"
                        htmlFor="date">
                        Preferred Appointment Date
                      </label>
                      <input
                        name="date"
                        value={date}
                        type="date"
                        placeholder="Appointment Date"
                        onChange={e => setDate(e.target.value)}
                        min="2024-10-01"
                        max="2024-10-31"
                        required
                      />
                    </div>
                    <input
                      type="hidden"
                      name="_gotcha"
                      style={{ display: "none !important" }}></input>
                    <button
                      type="submit"
                      className="standard-button contained mx-auto--mobile">
                      {!isLoading ? "Book Now" : "..."}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Columns>
        </Section>
      </div>
    </Layout>
  )
}

export const adCampaignBQuery = graphql`
  query adCampaignB($title: String!) {
    allAdCampaignsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          subheading
          heading
          image
          ebook {
            blurb
            book
            arrow
          }
          button {
            buttonText
            href
            destination
            appearance
          }
        }
        body
        button {
          buttonText
          href
          destination
          appearance
        }
      }
    }
    address: allLocationsJson(
      limit: 1
      sort: { fields: locationData___order }
    ) {
      nodes {
        locationData {
          stateAbbr
          streetName
          streetNumber
          cityName
          cityZip
        }
      }
    }
  }
`

export default AdCampaignB
